import React from 'react';

import css from './HeroPage.module.css';

const HeroPage = () => (
  <div className={css['parallax-hero-page']}>
    <video
      className={css['parallax-hero-page-video']}
      controls={false}
      playsInline
      autoPlay
      muted
      loop
      src="/assets/video/hero-page-video.mp4"
    />
    <div className={css['parallax-video-cover']}></div>
    <div className={css['parallax-content']}>
      <h1>
        Seeking perfection <br /> in everything we craft
      </h1>
    </div>
  </div>
);

export default HeroPage;
