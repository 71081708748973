import React from 'react';
import classNames from 'classnames';
import css from './ThankYouPopUp.module.css';

const ThankYouPopUp = ({ setContactToggle, backgroundPageScrollOn, setPriceRequestToggle }) => {
  const popUpAnimation = () => {
    const modalBox = document.getElementById('thank-you-popup');
    modalBox.classList.remove('animate__fadeIn');
    modalBox.classList.add('animate__fadeOut');

    setTimeout(() => {
      setContactToggle(false);
      setPriceRequestToggle(false);
      backgroundPageScrollOn();
    }, 500);
  };

  return (
    <div
      className={classNames(css['thanks-popup'], ['animate__animated animate__fadeIn'])}
      id={'thank-you-popup'}
    >
      <div className={classNames(css['title'], css['thanks-popup__header'])}>
        <h1 className={css['thanks-popup__title']}>Thank you!</h1>
        <img
          role="presentation"
          src="/assets/close.svg"
          alt="close"
          className={css['thanks-popup__close']}
          onClick={popUpAnimation}
        />
      </div>
      <div className={css['description']}>
        <div className={css['thanks-popup__description']}>
          We heard you and will get back to you very soon.
        </div>
      </div>
    </div>
  );
};

export default ThankYouPopUp;
