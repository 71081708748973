import React from 'react';
import ProductInformation from '../../../../newData/product-information.json';
import css from './ProductPageInformation.module.css';

const ProductPageInformation = ({ seasonName, title }) => {
  const instagramLink = 'https://www.instagram.com/narin_bags/';
  return (
    <section className={css['product-page__information']}>
      <div>
        <div className={css['title-desc-wrapper']}>
          <h1 className={css['title']}>{title}</h1>
          <div className={css['season']}>Season &ldquo;{seasonName}&rdquo;</div>
        </div>
        <div className={css['description']}>
          <div>{ProductInformation.Descripton}</div>
        </div>
      </div>

      <h1 className={css['title']}>
        <div className={css['info-title']}>Product information</div>
      </h1>
      <div>
        <hr className={css['underline']} />
        {ProductInformation.Information.map(item => (
          <div key={item.id} className={css['description-wrapper']}>
            <h3 className={css['info-description']}>{item.description}</h3>
            <div className={css['test']}>
              <p className={css['product-page__information--value']}>{item.value}</p>
            </div>
          </div>
        ))}
        <hr className={css['underline']} />
      </div>
      <div className={css['social-links']}>
        <div className={css['description']}>
          <div className={css['social-link-description']}>
            <span> Reach us directly on Instagram </span>
            <a href={instagramLink} target="_blank" className={css['instagram-logo']}>
              <img src="/assets/instagram.svg" alt="instagram logo" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductPageInformation;
