import React from 'react';
import haditiLogo from './assets/img.png';
import footerImage from './assets/footerImage.png';
import css from './ProductBuyInfo.module.css';

const title = 'Where to buy';
const info = `narin bags are presented exclusively at`;
const buyOnline = ` Or you can buy online at `;
const location = 'Emirates Towers Hotel, Sheikh Zayed Road, PO Box: 96435, Dubai, UAE';
const haditiLink = 'https://www.haditi.com/brands/narin.html';

const ProductBuyInfo = () => (
  <div className={css['buy']}>
    <img src={footerImage} alt={'tower'} className={css['tower-image']} />
    <div className={css['main-content']}>
      <div>
        <h1 className={css['title']}>{title}</h1>
      </div>
      <div>
        <p className={css['description-info']}>
          {info}{' '}
          <a href={haditiLink} className={css['link']} target={'_blank'}>
            Haditi Boutique
          </a>
        </p>
        <p className={css['description']}>{location}</p>
      </div>
      <div>
        <object data={haditiLogo} className={css['logo']} />
        <p className={css['description']}>
          {buyOnline}
          <a href={haditiLink} className={css['link']} target={'_blank'}>
            Haditi.com
          </a>
        </p>
      </div>
      <div>
        <span className={css['bottom-text']}> © narin 2023. All rights reserved.</span>
      </div>
    </div>
  </div>
);

export default ProductBuyInfo;
