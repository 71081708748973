/* eslint-disable max-len */
import React from 'react';
import css from './ProductIntroduction.module.css';
const ProductPageIntroduction = ({ seasonName, introductionInfo }) => {
  const matchedSeason = Object.keys(introductionInfo).filter(key => key.includes(seasonName));

  return (
    <div className={css['introduction']}>
      <h1 className={css['title']}>
        <div className={css['intro-title']}>Introduction</div>
      </h1>
      <div className={css['wrapper']}>
        <div className={css['description']}>{introductionInfo[matchedSeason]}</div>
        <div className={css['iframe-wrapper']}>
          <iframe
            title="Birth of the narin bags"
            src="https://www.youtube.com/embed/xMHl0R4fkXU"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={css['iframe']}
          />
        </div>
      </div>
    </div>
  );
};
export default ProductPageIntroduction;
