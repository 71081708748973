import React, { useEffect } from 'react';
import css from './PhotoToggle.module.css';
const PhotoToggle = ({ setExamplePhotoToggle, backgroundPageScrollOn, image, id }) => {
  useEffect(() => {
    document.addEventListener('keyup', setEscapeKeyHandler, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEscapeKeyHandler = e => e.key === 'Escape' && backgroundPageScrollOnHandler();

  const backgroundPageScrollOnHandler = () => {
    setExamplePhotoToggle(false);
    backgroundPageScrollOn();
    document.removeEventListener('keyup', setEscapeKeyHandler, false);
    window.onscroll = e => {
      // it's fix keypress bag
    };
  };

  return (
    <div
      role="presentation"
      className={css['overlay']}
      onClick={() => backgroundPageScrollOnHandler()}
    >
      <div className={css['product-page_modal']}>
        <div className={css['close']}>
          <img
            role="presentation"
            src="/assets/whiteCloseBtn.svg"
            alt="X"
            onClick={() => backgroundPageScrollOnHandler()}
          />
        </div>
        <img
          role="presentation"
          src={image}
          alt="packaging"
          className={css['img']}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
};

export default PhotoToggle;
