import React from 'react';
import CategoryName from '../CategoryName';
import SeasonName from '../SeasonName';
import ImagesSlider from '../ImagesSlider';

import css from './NewProductSlider.module.css';

const NewProductSlider = ({ bag, loading, setLoading, showSkeleton, setShowSkeleton }) => {
  const seasonNameData = 0;
  const imageObj = 1;

  return (
    <div>
      {!!bag &&
        bag?.map(([key, allContent]) => (
          <div key={key} className={css['all-product']}>
            <CategoryName name={key} loading={loading} />
            <SeasonName seasonName={allContent[seasonNameData]} loading={loading} />
            <ImagesSlider
              imageData={allContent[imageObj]}
              loading={loading}
              setLoading={setLoading}
              showSkeleton={showSkeleton}
              setShowSkeleton={setShowSkeleton}
            />
          </div>
        ))}
    </div>
  );
};

export default NewProductSlider;
