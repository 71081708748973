import React, { useState } from 'react';
import PhotoToggle from '../PhotoToggle/PhotoToggle';
import PortaledComponent from '../../../../components/PortaledComponent';
import css from './ProductPagePackaging.module.css';

const ProductPagePackaging = ({
  packagingBoxes,
  setExamplePhotoToggle,
  backgroundPageScrollOff,
  examplePhotoToggle,
  backgroundPageScrollOn,
}) => {
  const [activeImage, setActiveImage] = useState('');

  return (
    <div className={css['packaging']}>
      <h1 className={css['title']}>Packaging</h1>
      <div className={css['items']}>
        {packagingBoxes.map(i => (
          <div key={i.id}>
            <div className={css['item']}>
              <div className={css['image-wrapper']}>
                <img
                  src={i.image}
                  className={css['img']}
                  alt="Packaging"
                  role="presentation"
                  onClick={() => {
                    backgroundPageScrollOff();
                    setExamplePhotoToggle(true);
                    setActiveImage(i?.image);
                  }}
                />
              </div>
            </div>
            <div className={css['footer']}>
              {examplePhotoToggle && (
                <PortaledComponent
                  modal={
                    <PhotoToggle
                      backgroundPageScrollOn={backgroundPageScrollOn}
                      setExamplePhotoToggle={setExamplePhotoToggle}
                      image={activeImage}
                    />
                  }
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPagePackaging;
