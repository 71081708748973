// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomePage_home-page__YA8LH {\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n.HomePage_home-page__YA8LH .HomePage_section-wrapper__AJohU {\n  background-color: #fff;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".home-page {\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n.home-page .section-wrapper {\n  background-color: #fff;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-page": "HomePage_home-page__YA8LH",
	"section-wrapper": "HomePage_section-wrapper__AJohU"
};
export default ___CSS_LOADER_EXPORT___;
