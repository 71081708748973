import React from 'react';
import './Menu.css';

const MenuLinks = ({ isLogoWhite }) => (
  <div className="menu">
    <nav className="menu__items" style={isLogoWhite ? { color: '#fff' } : { color: '#070707' }}>
      <a
        href="/#about"
        className="menu__item"
        target={'_self'}
      >
        About us
      </a>

      <a
        href="/#our-works"
        className="menu__item"
        target={'_self'}
      >
        Our works
      </a>

      <a
        href="/#contact-us"
        className="menu__item"
        target={'_self'}
      >
        Contact us
      </a>
    </nav>
  </div>
);

export default MenuLinks;
