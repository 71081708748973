import React from 'react';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton';
import { Navigation } from 'swiper';

import 'react-loading-skeleton/dist/skeleton.css';
import css from './ImagesSlider.module.css';
import './swiper.min.css';

const ImagesSlider = ({ imageData, loading }) => {
  const featuredImage = 0;
  const secondaryImage = 1;

  return (
    <div className={css['swiper-and-button-wrapper']}>
      <Swiper
        breakpoints={{
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        navigation={{
          nextEl: '#swiper-forward',
          prevEl: '#swiper-back',
        }}
        spaceBetween={7}
        speed={520}
        modules={[Navigation]}
        className={css['images-slider']}
      >
        {imageData.map(subContent => (
          <SwiperSlide key={subContent.id}>
            <NavLink
              to={`${process.env.PUBLIC_URL}/${subContent.name}/${subContent.id}`}
              aria-label={'subContent.id'}
            >
              <div className={css['images-slider__image-wrapper']}>
                {loading ? (
                  <img
                    type="image/webp"
                    src={subContent.thumbnails[featuredImage]}
                    alt={subContent.name}
                    className={css['images-slider__featured-image']}
                  />
                ) : (
                  <Skeleton className={css['slider-skeleton']} baseColor="#ffffff" />
                )}
                <img
                  type="image/webp"
                  src={subContent.thumbnails[secondaryImage]}
                  alt={subContent.name}
                  className={css['images-slider__secondary-image']}
                />
              </div>
              {loading ? (
                <span className={css['images-slider__title']}>{subContent.name}</span>
              ) : (
                <Skeleton className={css['bag-name-skeleton']} />
              )}
            </NavLink>
          </SwiperSlide>
        ))}
        <div className={css['swiper-button-prev']} id={'swiper-back'}></div>
        <div className={css['swiper-button-next']} id={'swiper-forward'}></div>
      </Swiper>
    </div>
  );
};

export default ImagesSlider;
