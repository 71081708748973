import React, { useState } from 'react';
import classNames from 'classnames';
import ThankYouPopUp from '../ThankYouPopup/ThankYouPopUp';
import { CountryDropdown } from 'react-country-region-selector';
import Textarea from '../Textarea/Textarea';

import css from './PriceRequest.module.css';
import 'animate.css';

const PriceRequest = ({
  setPriceRequestToggle,
  backgroundPageScrollOn,
  frontImage,
  title,
  seasonName,
  inputHandler,
  setContactToggle,
  messageHandler,
  handleSubmit,
  confirm,
  countryHandler,
  country,
  mail,
  message,
  errors,
}) => {
  const handleAnimation = () => {
    const modalBox = document.getElementById('price-req');
    modalBox.classList.remove('animate__fadeIn');
    modalBox.classList.add('animate__fadeOut');

    setTimeout(() => {
      setPriceRequestToggle(false);
      backgroundPageScrollOn();
    }, 500);
  };

  return (
    <>
      <div
        className={classNames(css['page-bg'], css['price-bg'], [
          'animate__animated animate__fadeIn',
        ])}
        id="price-req"
      >
        <div className={css['background-click']} onClick={handleAnimation}></div>
        {confirm ? (
          <ThankYouPopUp
            setContactToggle={setContactToggle}
            backgroundPageScrollOn={backgroundPageScrollOn}
            setPriceRequestToggle={setPriceRequestToggle}
          />
        ) : (
          <>
            <div className={css['price-request']}>
              <h1 className={css['title']}>
                <div className={classNames(css['price-request__title'], css['align-center'])}>
                  <div className={css['price-request__close']}>
                    <span>Price request</span>
                    <img
                      role="presentation"
                      src="/assets/close.svg"
                      alt="Closing button"
                      className={css['price-request__close--btn']}
                      onClick={handleAnimation}
                    />
                  </div>
                </div>
              </h1>
              <div className={css['price-request__content']}>
                <div className={css['price-request__content--mobile']}>
                  <img src={frontImage} className={css['price-request__img']} alt="Bag front" />
                  <div>
                    <h1 className={css['title']}>
                      <div className={css['price-request__title--mobile']}>{title}</div>
                    </h1>
                    <div
                      className={classNames(css['description'], css['price-request__description'])}
                    >
                      <div className={css['price-request__info']}>
                        Season &ldquo;{seasonName}&rdquo;
                      </div>
                    </div>
                    <div className={classNames(css['description'], css[' price-request__cost'])}>
                      Our product costs start from $2500
                    </div>
                  </div>
                </div>
              </div>
              <div className={css['price-request__form']}>
                <div className={css['title']}>
                  <div className={css['price-request__form--title']}>
                    Complimentary shipping for all orders
                  </div>
                </div>
                <div className={css['price-request__form--wrap']}>
                  <div
                    className={classNames(
                      css['price-request__form--inputs'],
                      css['price-request__form--email'],
                    )}
                  >
                    <label htmlFor="contacting-input">Your email address*</label>
                    <input
                      type="text"
                      id="contacting-input"
                      className={classNames(css['input'], css['price-request__form--input'])}
                      placeholder="yourmail@example.com"
                      onChange={inputHandler}
                      value={mail}
                      required
                    />
                    <div>{errors.mail && "*This field can't be empty."}</div>
                  </div>
                  <div
                    className={classNames(
                      css['price-request__form--inputs'],
                      css['price-request__form--country'],
                    )}
                  >
                    <label htmlFor="contacting-input--country">Country*</label>
                    <CountryDropdown
                      id="contacting-input--country"
                      className={classNames(css['input'], css['price-request__form--input'])}
                      value={country}
                      onChange={countryHandler}
                      required
                    />
                    <div>{errors.mail && "*This field can't be empty."}</div>
                  </div>
                </div>
                <div className={css['price-request__textarea']}>
                  <Textarea
                    placeholder="Your message (optional)"
                    messageHandler={messageHandler}
                    message={message}
                  />
                </div>
                <div className={css['price-request__form--description']}>
                  We will get back to you in less than a day
                </div>
                <div className={css['price-request__form--btn']}>
                  <button
                    className={classNames(css['btn'], css['price-request__btn'])}
                    onClick={handleSubmit}
                  >
                    Request the price
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PriceRequest;
