import React from 'react';
import Skeleton from 'react-loading-skeleton';
import css from './SeasonName.module.css';

const SeasonName = ({ seasonName, loading }) => {
  return (
    <div className={css['name-wrapper']}>
      {loading ? (
        <span className={css['season-name']}> {`Season "${seasonName}"`} </span>
      ) : (
        <Skeleton className={css['skeleton-season-name']} />
      )}
    </div>
  );
};

export default SeasonName;
