import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import MenuLinks from '../MenuLinks/MenuLinks';
import css from './Header.module.css';

const Header = ({ isLogoWhite, setIsLogoWhite }) => {
  const [burgerMenuToggle, setBurgerMenuToggle] = useState(true);

  const userMonitorAvailWidth = window.innerWidth;

  const isDesktopSize = userMonitorAvailWidth > 1024;

  const isLogoWhiteHandler = () => {
    if (isDesktopSize) {
      return isLogoWhite ? '/assets/logo-slogan.svg' : '/assets/logo-slogan-black.svg';
    }
    return isLogoWhite ? '/assets/narinSmallLogo.svg' : '/assets/blackSmallLogo.svg';
  };

  const hamburgerMenuSrc = isLogoWhite
    ? '/assets/hamburger-menu-white.svg'
    : '/assets/hamburger-menu-black.svg';

  const burgerMenuToggleHandler = burgerMenuToggle
    ? hamburgerMenuSrc
    : '/assets/hamburger-menu-close.svg';

  const setIsBurgerMenuWhiteHandler = () => {
    setBurgerMenuToggle(!burgerMenuToggle);
    setIsLogoWhite(!isLogoWhite);
  };
  return (
    <div className={css['header-overlay-wrapper']}>
      <div className={css['overlay-white']}>
        <header
          className={cn(
            css['header'],
            burgerMenuToggle ? css['header__bg--transparent'] : css['header__bg--white'],
          )}
        >
          <div className={css['header__content']}>
            <NavLink to={`${process.env.PUBLIC_URL}/`}>
              <img src={isLogoWhiteHandler()} alt="narin logo" />
            </NavLink>

            <div className={css['header__links']}>
              <MenuLinks isLogoWhite={isLogoWhite} />
            </div>

            <div className={css['header__burger']}>
              <img
                role="presentation"
                src={burgerMenuToggleHandler}
                alt="hamburger-menu"
                onClick={() => setIsBurgerMenuWhiteHandler()}
              />

              <div className={css['header__burger--links']}>
                {!burgerMenuToggle && <MenuLinks isLogoWhite={isLogoWhite} />}
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
