// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoryName_category-name__7Evzb {\n  text-align: center;\n  font-size: 36px;\n  font-family: 'CormorantGaramond-Bold';\n  letter-spacing: 0px;\n  color: #070707;\n  padding-bottom: 5px;\n  display: inline;\n}\n\n.CategoryName_category-skeleton__UgT3D {\n  width: 180px;\n  height: 35px;\n  padding-top: 25px;\n}\n\n.CategoryName_name-wrapper__Wx-VH {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/CategoryName/CategoryName.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,qCAAqC;EACrC,mBAAmB;EACnB,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".category-name {\n  text-align: center;\n  font-size: 36px;\n  font-family: 'CormorantGaramond-Bold';\n  letter-spacing: 0px;\n  color: #070707;\n  padding-bottom: 5px;\n  display: inline;\n}\n\n.category-skeleton {\n  width: 180px;\n  height: 35px;\n  padding-top: 25px;\n}\n\n.name-wrapper {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"category-name": "CategoryName_category-name__7Evzb",
	"category-skeleton": "CategoryName_category-skeleton__UgT3D",
	"name-wrapper": "CategoryName_name-wrapper__Wx-VH"
};
export default ___CSS_LOADER_EXPORT___;
