import React, { useState } from 'react';
import ProductPageDesktopSlider from '../ProductPageDesktopSlider';
import ProductPageMobileSlider from '../ProductPageMobileSlider';
import PhotoToggle from '../../pages/ProductPage/product_page_content/PhotoToggle/PhotoToggle';
import PortaledComponent from '../PortaledComponent/PortaledComponent';

import './ProductPageSlider.css';

const ProductPageSlider = ({
  smallImages,
  largeImage,
  middleImage,
  imageId,
  setImageId,
  setImageIdDecrementHandler,
  backgroundPageScrollOn,
  setContactToggle,
  setImageIdIncrementHandler,
  userDeviceScreenSize,
  setExamplePhotoToggle,
  examplePhotoToggle,
  backgroundPageScrollOff,
  originalSizeImages,
  isAeonPage,
  loading,
}) => {
  const [activeImage, setActiveImage] = useState('');

  return (
    <div className="product-page-slider-wrap">
      {userDeviceScreenSize > 1279 ? (
        <>
          <ProductPageDesktopSlider
            smallImages={smallImages}
            largeImage={largeImage}
            imageId={imageId}
            setImageId={setImageId}
            originalSizeImages={originalSizeImages}
            setImageIdIncrementHandler={setImageIdIncrementHandler}
            setImageIdDecrementHandler={setImageIdDecrementHandler}
            setExamplePhotoToggle={setExamplePhotoToggle}
            setActiveImage={setActiveImage}
            loading={loading}
            backgroundPageScrollOn={backgroundPageScrollOn}
            setContactToggle={setContactToggle}
            backgroundPageScrollOff={backgroundPageScrollOff}
            isAeonPage={isAeonPage}
          />
          {examplePhotoToggle && (
            <PortaledComponent
              modal={
                <PhotoToggle setExamplePhotoToggle={setExamplePhotoToggle} image={activeImage} />
              }
            />
          )}
        </>
      ) : (
        <ProductPageMobileSlider
          middleImage={largeImage}
          smallImages={smallImages}
          imageId={imageId}
          loading={loading}
          setImageId={setImageId}
          setImageIdIncrementHandler={setImageIdIncrementHandler}
          setImageIdDecrementHandler={setImageIdDecrementHandler}
        />
      )}
    </div>
  );
};

export default ProductPageSlider;
