import React from 'react';
import Textarea from '../Textarea/Textarea';
import ThankYouPopUp from '../ThankYouPopup/ThankYouPopUp';
import css from './ContactPopup.module.css';
import classNames from 'classnames';

import 'animate.css';

const ContactPopup = ({
  setContactToggle,
  backgroundPageScrollOn,
  inputHandler,
  messageHandler,
  handleSubmit,
  mail,
  message,
  errors,
  confirm,
}) => {
  
  const getInTouch =
    "If you have any questions, queries or comments, feel free to get in touch with us at info@narinbags.com. We'd delighted to help you.";

  const popUpAnimation = () => {
    const modalBox = document.getElementById('contact-us-section');
    modalBox.classList.remove('animate__fadeIn');
    modalBox.classList.add('animate__fadeOut');

    setTimeout(() => {
      setContactToggle(false);
      backgroundPageScrollOn();
    }, 500);
  };

  return (
    <div className={css['overlay']}>
      <div className={css['background-click']} onClick={popUpAnimation}></div>
      {confirm ? (
        <ThankYouPopUp
          setContactToggle={setContactToggle}
          backgroundPageScrollOn={backgroundPageScrollOn}
        />
      ) : (
        <section
          id="contact-us-section"
          className={classNames(css['contacting-component'], ['animate__animated animate__fadeIn'])}
        >
          <div className={css['header']}>
            <div className={css['title']}>Contacting narin</div>
            <div className={css['close']}>
              <img
                role="presentation"
                src="/assets/blackCloseBtn.svg"
                alt="close"
                onClick={popUpAnimation}
              />
            </div>
          </div>
          <div className={css['description-wrapper']}>
            <div className={css['description']}>{getInTouch}</div>
          </div>
          <div className={css['label']}>
            <label htmlFor="contacting-input" className={css['description']}>
              Your email address*
            </label>
          </div>
          <input
            type="text"
            id="contacting-input"
            className={css['input']}
            placeholder="yourmail@example.com"
            onChange={inputHandler}
            value={mail}
            required
          />
          <div className={css['empty-filed']}>{errors.mail && "*This field can't be empty."}</div>
          <div className={css['textarea']}>
            <Textarea
              placeholder="Your message here"
              messageHandler={messageHandler}
              message={message}
            />
          </div>
          <div className={css['empty-filed-message']}>
            {errors.message && "*This field can't be empty."}
          </div>

          <button className={css['btn']} onClick={handleSubmit}>
            Submit
          </button>
        </section>
      )}
    </div>
  );
};

export default ContactPopup;
