import React, { useState } from 'react';
import classnames from 'classnames';

import ImagesMagnifiers from '../ImagesMagnifiers/ImagesMagnifiers';
import PortaledComponent from '../PortaledComponent';
import SliderModal from '../SliderModal';
import Skeleton from 'react-loading-skeleton';

import css from './ProductPageDesktopSlider.module.css';

const ProductPageDesktopSlider = ({
  smallImages,
  largeImage,
  setImageId,
  imageId,
  loading,
  backgroundPageScrollOn,
  backgroundPageScrollOff,
  setContactToggle,
  originalSizeImages,
  isAeonPage,
}) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div className={css['slider-miniImages']}>
        {smallImages.map((img, index) =>
          loading ? (
            <div
              role="presentation"
              key={index}
              style={{ background: `url('${img}') no-repeat center center / cover` }}
              className={classnames(
                css['product-page-miniImages'],
                index === imageId && css['active'],
              )}
              onClick={() => setImageId(index)}
            />
          ) : (
            <Skeleton key={index} className={css['skeleton-small-images']} />
          ),
        )}
      </div>
      <div className={css['product-page-slider']}>
        <div>
          <div className={css['curs']}>
            {largeImage && (
              <div>
                <div onClick={() => [setModal(true), backgroundPageScrollOff()]}>
                  {loading ? (
                    <ImagesMagnifiers
                      img={largeImage}
                      secondImg={largeImage}
                      largeImg={largeImage}
                      secondLargeImg={largeImage}
                      imageId={imageId}
                      load={loading}
                    />
                  ) : (
                    <Skeleton className={css['magnifier-skeleton']} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {modal && (
        <PortaledComponent
          modal={
            <SliderModal
              originalSizedImages={originalSizeImages}
              imageId={imageId}
              largeImage={largeImage}
              closeModal={setModal}
              backgroundPageScrollOn={backgroundPageScrollOn}
              setContactToggle={setContactToggle}
              isAeonPage={isAeonPage}
            />
          }
        />
      )}
    </>
  );
};

export default ProductPageDesktopSlider;
