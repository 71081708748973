/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Hammer from 'react-hammerjs';
import classnames from 'classnames';
import ImagesMagnifiers from '../ImagesMagnifiers/ImagesMagnifiers';
import css from './ProductPageMobileSlider.module.css';
import Skeleton from 'react-loading-skeleton';

const ProductPageMobileSlider = ({
  middleImage,
  setImageIdIncrementHandler,
  setImageIdDecrementHandler,
  smallImages,
  setImageId,
  imageId,
  loading,
}) => {
  console.log(middleImage);
  return (
    <div className={css['product-page-slider']}>
      {middleImage && (
        <div>
          <Hammer
            onSwipeLeft={() => setImageIdIncrementHandler()}
            onSwipeRight={() => setImageIdDecrementHandler()}
          >
            <div className={css['product-page__slider--curs']}>
              <div className={css['product-page__slider--arrows']}>
                <img
                  role="presentation"
                  className={css['product-page__slider--left-arrow']}
                  src="/assets/left-arrow.svg"
                  alt="Left Arrow"
                  onClick={() => setImageIdDecrementHandler()}
                />

                <img
                  role="presentation"
                  className={css['product-page__slider--right-arrow']}
                  src="/assets/right-arrow.svg"
                  alt="Right Arrow"
                  onClick={() => setImageIdIncrementHandler()}
                />
              </div>
              {loading ? (
                <ImagesMagnifiers img={`${middleImage}`} />
              ) : (
                <Skeleton className={css['magnifier-skeleton']} />
              )}
            </div>
          </Hammer>
        </div>
      )}
      <div className={css['mobile-mini-images']}>
        {smallImages.map((img, index) =>
          loading ? (
            <div
              role="presentation"
              key={index}
              style={{ background: `url('${img}') no-repeat center center / cover` }}
              className={classnames(
                css['product-page-miniImages'],
                index === imageId && css['product-page-miniImages__active'],
              )}
              onClick={() => setImageId(index)}
            />
          ) : (
            <Skeleton className={css['small-images-skeleton']} />
          ),
        )}
      </div>
    </div>
  );
};

export default ProductPageMobileSlider;
