import React, { useEffect, useState } from 'react';

import Header from '../../components/Header';
import About from '../../components/About';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';
import ContactPopup from '../../components/ContactPopup';
import HeroPage from '../../components/HeroPage/HeroPage';
import NewProductSlider from '../../components/NewProductSlider';
import PortaledComponent from '../../components/PortaledComponent';

import 'react-loading-skeleton/dist/skeleton.css';
import css from './HomePage.module.css';
import 'animate.css';

const HomePage = ({
  isOpenConfirmationModal,
  toggleConfirmationModal,
  backgroundPageScrollOn,
  backgroundPageScrollOff,
  setContactToggle,
  messageHandler,
  setIsLogoWhite,
  contactToggle,
  handleSubmit,
  inputHandler,
  isLogoWhite,
  mail,
  bag,
  message,
  errors,
  confirm,
  setConfirm,
}) => {
  const [homeLoading, setHomeLoading] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      const timer = setTimeout(() => setHomeLoading(true), 1500);
      return () => clearTimeout(timer);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    window.onscroll = e => {
      // it's fix scrollOff bag after priceRequest goBack function
    };
  }, []);

  return (
    <div className={css['home-page']}>
      <Header isLogoWhite={isLogoWhite} setIsLogoWhite={setIsLogoWhite} />
      <HeroPage />
      <div className={css['section-wrapper']}>
        <About loading={homeLoading} />
        <NewProductSlider bag={bag} loading={homeLoading} />
        <ContactUs
          setContactToggle={setContactToggle}
          backgroundPageScrollOff={backgroundPageScrollOff}
        />
        <Footer />
        {contactToggle && (
          <PortaledComponent
            modal={
              <ContactPopup
                setContactToggle={setContactToggle}
                backgroundPageScrollOn={backgroundPageScrollOn}
                isToggled={contactToggle}
                isOpenConfirmationModal={isOpenConfirmationModal}
                toggleConfirmationModal={toggleConfirmationModal}
                inputHandler={inputHandler}
                mail={mail}
                message={message}
                errors={errors}
                messageHandler={messageHandler}
                setConfirm={setConfirm}
                confirm={confirm}
                handleSubmit={handleSubmit}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default HomePage;
