import React, { useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import 'react-image-magnifiers/dist/styles';
import './imagesMagnifiers.css';

const ImagesMagnifiers = ({ img, largeImg = '' }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowSize < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <>
      {!isMobile ? (
        <div className={'magnifier'}>
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: 'Wristwatch by Ted Baker London',
                width: 731,
                height: 610,
                src: img,
              },
              largeImage: {
                src: largeImg,
                width: 1131,
                height: 710,
              },
              enlargedImageContainerClassName: 'large-image-container',
              enlargedImageClassName: 'large-image',
              imageClassName: 'small-image',
              enlargedImageContainerDimensions: {
                width: '75%',
                height: '100%',
              },
            }}
          />
        </div>
      ) : (
        <div className={'mobile-view'}>
          <img alt={'bag image'} src={img} className={'mobile-img'} />
        </div>
      )}
    </>
  );
};

export default ImagesMagnifiers;
