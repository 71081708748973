import React from 'react';
import './Textarea.css';

const Textarea = ({ placeholder, messageHandler, message }) => (
  <textarea
    className="textarea"
    onChange={messageHandler}
    placeholder={placeholder}
    value={message}
    type="text"
    required
  ></textarea>
);

export default Textarea;
