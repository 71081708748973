// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeasonName_season-name__R9k4h {\n  display: inline-block;\n  text-align: center;\n  letter-spacing: 0px;\n  color: #070707;\n  opacity: 1;\n  font-family: Raleway-Regular;\n  font-size: 18px;\n  padding-bottom: 30px;\n}\n\n.SeasonName_name-wrapper__L\\+29W {\n  text-align: center;\n}\n.SeasonName_skeleton-season-name__UbG3t {\n  width: 210px;\n  height: 30px;\n  padding-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SeasonName/SeasonName.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,UAAU;EACV,4BAA4B;EAC5B,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".season-name {\n  display: inline-block;\n  text-align: center;\n  letter-spacing: 0px;\n  color: #070707;\n  opacity: 1;\n  font-family: Raleway-Regular;\n  font-size: 18px;\n  padding-bottom: 30px;\n}\n\n.name-wrapper {\n  text-align: center;\n}\n.skeleton-season-name {\n  width: 210px;\n  height: 30px;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"season-name": "SeasonName_season-name__R9k4h",
	"name-wrapper": "SeasonName_name-wrapper__L+29W",
	"skeleton-season-name": "SeasonName_skeleton-season-name__UbG3t"
};
export default ___CSS_LOADER_EXPORT___;
