import React, { Suspense, useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import useSWR from 'swr';

import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';

const App = () => {
  const [userDeviceScreenSize, setUserDeviceScreenSize] = useState(window.screen.width);
  const [contactToggle, setContactToggle] = useState(false);
  // email
  const emailRegex = /\S+@\S+\.\S+/;

  const initialValue = { mail: false, message: false };
  const [errors, setErrors] = useState({ ...initialValue });
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');
  const [country, setCountry] = useState('');
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  const fetcher = url => fetch(url).then(res => res.json());
  const { data } = useSWR('/products.json', fetcher);

  const toggleConfirmationModal = useCallback(() => {
    setConfirm(!confirm);
  }, [confirm]);

  const inputHandler = e => {
    setMail(e.target.value);
  };

  const messageHandler = e => {
    setMessage(e.target.value);

    if (setMessage.length && errors.message) {
      setErrors({
        ...errors,
        message: false,
      });
    }
  };

  const countryHandler = val => {
    setCountry(val);
    if (setCountry.length && errors.country) {
      setErrors({
        ...errors,
        message: false,
      });
    }
  };
  const handleSubmit = useCallback(() => {
    const newErrors = { ...initialValue };
    if (mail.length === 0 || !emailRegex.test(mail)) {
      newErrors.mail = true;
    }

    if (message.length === 0) {
      newErrors.message = true;
    }

    setErrors(newErrors);

    const isFormValid = Object.values(newErrors).indexOf(true) === -1;

    if (isFormValid) {
      const formData = new FormData();

      formData.append('email', mail);
      formData.append('message', message);
      formData.append('country', country);

      fetch(process.env.REACT_APP_EMAIL_URL, {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(result => {
          setConfirm(true);
          toggleConfirmationModal();
        });
    }
  }, [mail, message, country]);

  useEffect(() => {
    const onResizeHandler = () => {
      setUserDeviceScreenSize(window.screen.width);
    };
    window.addEventListener('resize', onResizeHandler);

    return () => window.removeEventListener('resize', onResizeHandler);
  }, []);

  const [isLogoWhite, setIsLogoWhite] = useState(true);
  const scrollOff = () => {
    const html = document.querySelector('html');
    html.style.scrollBehavior = 'unset';
    const yPosition = window.pageYOffset;
    window.onscroll = () => {
      window.scrollTo(0, yPosition);
    };
  };

  const scrollOn = () => {
    window.onscroll = e => {};
  };

  const html = document.querySelector('html');

  const backgroundPageScrollOff = () => {
    html.classList.add('hide-overflow');
    document.body.style.overflowY = 'scroll';
  };

  const backgroundPageScrollOn = () => {
    html.classList.remove('hide-overflow');
    document.body.style.overflowY = 'scroll';
  };

  const entries = !!data && Object.entries(data);
  return (
    <BrowserRouter>
      <div className="container">
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL}/`}
            exact
            render={() => (
              <HomePage
                bag={entries}
                isLogoWhite={isLogoWhite}
                setIsLogoWhite={setIsLogoWhite}
                backgroundPageScrollOn={backgroundPageScrollOn}
                backgroundPageScrollOff={backgroundPageScrollOff}
                userDeviceScreenSize={userDeviceScreenSize}
                contactToggle={contactToggle}
                isToggled={contactToggle}
                isOpenConfirmationModal={isOpenConfirmationModal}
                toggleConfirmationModal={toggleConfirmationModal}
                inputHandler={inputHandler}
                mail={mail}
                message={message}
                errors={errors}
                messageHandler={messageHandler}
                handleSubmit={handleSubmit}
                setContactToggle={setContactToggle}
                showSkeleton={showSkeleton}
                setShowSkeleton={setShowSkeleton}
                setConfirm={setConfirm}
                confirm={confirm}
              />
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/:collectionUrl/:productId`}
            render={() => (
              <Suspense fallback={() => <img src="/assets/preloader.svg" alt="...Loading" />}>
                {data && (
                  <ProductPage
                    backgroundPageScrollOn={backgroundPageScrollOn}
                    backgroundPageScrollOff={backgroundPageScrollOff}
                    userDeviceScreenSize={userDeviceScreenSize}
                    setIsLogoWhite={setIsLogoWhite}
                    bag={entries}
                    isToggled={contactToggle}
                    isOpenConfirmationModal={isOpenConfirmationModal}
                    toggleConfirmationModal={toggleConfirmationModal}
                    inputHandler={inputHandler}
                    mail={mail}
                    message={message}
                    errors={errors}
                    messageHandler={messageHandler}
                    handleSubmit={handleSubmit}
                    setContactToggle={setContactToggle}
                    setCountry={setCountry}
                    countryHandler={countryHandler}
                    country={country}
                    setConfirm={setConfirm}
                    confirm={confirm}
                  />
                )}
              </Suspense>
            )}
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
