import React, { useState } from 'react';
import cn from 'classnames';

import aboutData from './AboutData';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import css from './About.module.css';

const About = ({ loading }) => {
  const userDeviceScreenSize = window.screen.width;
  const [currentTargetBlock, setCurrentTargetBlock] = useState([]);

  const isExistsId = itemId => currentTargetBlock.includes(itemId);

  return (
    <section className={css['about']}>
      {aboutData.map(item => (
        <div
          className={cn(css['about__content'], { about__top: item.id === css['about'] })}
          key={item.id}
          id={item.id}
        >
          {loading ? (
            <h1 className={css['about__content--title']}>{item.title}</h1>
          ) : (
            <Skeleton className={css['title-skeleton']} />
          )}
          {loading ? (
            <div className={css['description']}>
              <div className={css['about__content--text']}>{item.visibleText}</div>
            </div>
          ) : (
            <Skeleton className={css['content-skeleton']} count={11} />
          )}
          {loading ? (
            <div
              role="presentation"
              className={cn(
                css['about__read-more'],
                userDeviceScreenSize > 960 && !isExistsId(item.id) ? css['about__hideText'] : '',
                isExistsId(item.id) ? css['about__hideText'] : '',
              )}
              id={item.id}
              onClick={() => {
                setCurrentTargetBlock(prevState => [...prevState, item.id]);
              }}
            >
              Read More...
            </div>
          ) : (
            <Skeleton className={css['learn-more-skeleton']} />
          )}
          {loading ? (
            <div
              className={cn(
                css['description'],
                userDeviceScreenSize < 960 && !isExistsId(item.id) ? css['about__hideText'] : '',
              )}
            >
              <div className={[css['about__content--text'], css['second-part']]}>
                {item.hideText}{' '}
              </div>
            </div>
          ) : (
            <Skeleton className={css['hidden-text-skeleton']} count={4} />
          )}
        </div>
      ))}
    </section>
  );
};

export default About;
