import React from 'react';
import css from './Footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={css["footer"]}>
      <div className={css["description"]}>© narin {currentYear}. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
