// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footer__kXlYa {\n  padding: 16px 0 30px 0;\n  text-align: center;\n  margin: 0 16px;\n}\n\n.Footer_description__UJWcL {\n  font-size: 16px;\n  color: #000;\n  line-height: 1.5;\n  font-family: 'Raleway-Regular';\n}\n@media screen and (min-width: 1314px) {\n  .Footer_footer__kXlYa {\n    max-width: 1314px;\n    margin: 0 auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,8BAA8B;AAChC;AACA;EACE;IACE,iBAAiB;IACjB,cAAc;EAChB;AACF","sourcesContent":[".footer {\n  padding: 16px 0 30px 0;\n  text-align: center;\n  margin: 0 16px;\n}\n\n.description {\n  font-size: 16px;\n  color: #000;\n  line-height: 1.5;\n  font-family: 'Raleway-Regular';\n}\n@media screen and (min-width: 1314px) {\n  .footer {\n    max-width: 1314px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__kXlYa",
	"description": "Footer_description__UJWcL"
};
export default ___CSS_LOADER_EXPORT___;
