import React from 'react';
import Skeleton from 'react-loading-skeleton';
import css from './CategoryName.module.css';

const CategoryName = ({ name, loading }) => (
  <div className={css['name-wrapper']}>
    {loading ? (
      <h1 className={css['category-name']}>{name}</h1>
    ) : (
      <Skeleton className={css['category-skeleton']} />
    )}
  </div>
);

export default CategoryName;
