import React from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import css from './SliderModal.module.css';

const SliderModal = ({
  largeImage,
  imageId,
  closeModal,
  backgroundPageScrollOn,
  originalSizedImages,
  isAeonPage,
}) => {
  const modalAnimation = () => {
    const modalBox = document.getElementById('slider-on-modal');
    modalBox.classList.remove('animate__fadeIn');
    modalBox.classList.add('animate__fadeOut');

    setTimeout(() => {
      closeModal(false);
      backgroundPageScrollOn();
    }, 500);
  };

  return (
    <div
      id="slider-on-modal"
      className={classNames(css['modal-slider-wrapper'], ['animate__animated animate__fadeIn'])}
    >
      <div className={css['overlay']} onClick={modalAnimation}></div>
      <Swiper
        className={css['modal-slider']}
        navigation={{
          nextEl: '#swiper-next',
          prevEl: '#swiper-prev',
        }}
        modules={[Navigation]}
        initialSlide={imageId}
        loop
      >
        <div className={css['close-btn-wrapper']} onClick={modalAnimation}>
          <img src="/assets/close.svg" alt="closing icon" className={css['close-btn']} />
        </div>
        {originalSizedImages.map(
          (images, index) =>
            largeImage && (
              <SwiperSlide activeIndex={imageId} key={index} className={css['slide-image-wrapper']}>
                <img
                  src={images}
                  id={imageId}
                  alt="slide image"
                  className={classNames(css['slide-image'], {
                    [css['aeon-slide']]: isAeonPage && index === 4,
                  })}
                />
              </SwiperSlide>
            ),
        )}
        <div className={css['swiper-button-prev']} id={'swiper-prev'}></div>
        <div className={css['swiper-button-next']} id={'swiper-next'}></div>
      </Swiper>
    </div>
  );
};
export default SliderModal;
