import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import ProductPageSlider from '../../components/ProductPageSlider';
import ProductPageInformation from './product_page_content/ProductPageInformation/ProductPageInformation';
import ProductPageIntroduction from './product_page_content/ProductIntroduction/productPageIntroduction';
import ProductPagePackaging from './product_page_content/ProductPagePackaging/ProductPagePackaging';
import ProductBuyInfo from './product_page_content/ProductBuyInfo/ProductBuyInfo';
import Footer from '../../components/Footer/Footer';
import PriceRequest from '../../components/PriceRequest/PriceRequest';
import PortaledComponent from '../../components/PortaledComponent';
import packagingBoxes from '../../data/packages.json';
import introductionInfo from '../../newData/category-introduction.json';

import css from './productPage.module.css';
import 'animate.css';

const ProductPage = ({
  backgroundPageScrollOn,
  backgroundPageScrollOff,
  setIsLogoWhite,
  userDeviceScreenSize,
  setContactToggle,
  isOpenConfirmationModal,
  toggleConfirmationModal,
  inputHandler,
  messageHandler,
  countryHandler,
  handleSubmit,
  confirm,
  setConfirm,
  setCountry,
  country,
  bag,
  mail,
  message,
  contactToggle,
  errors,
}) => {
  const [examplePhotoToggle, setExamplePhotoToggle] = useState(false);
  const [priceRequestToggle, setPriceRequestToggle] = useState(false);
  const [packagingType, setPackagingType] = useState(1);
  const [imageId, setImageId] = useState(0);
  const params = useParams();
  const [bagPageLoading, setBagPageLoading] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      const timer = setTimeout(() => setBagPageLoading(true), 1000);
      return () => clearTimeout(timer);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const handleOnChangeType = type => {
    setPackagingType(type);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    backgroundPageScrollOn();
    return () => setPriceRequestToggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bags = [];
  const patterns = bag[0][1][1];
  const land = bag[1][1][1];
  const geniuses = bag[2][1][1];
  bags.push(...patterns, ...land, ...geniuses);
  const collection = bags.filter(url => url.name === params.collectionUrl);
  const sliderId = collection[0].id - 1;
  const seasonName = collection[0]?.seasonName;
  const title = collection[0]?.name;
  const middleImage = bags[sliderId].images[imageId];
  const largeImage = bags[sliderId].images[imageId];

  const setImageIdIncrementHandler = () => {
    if (imageId >= bags[sliderId].images.length - 1) {
      setImageId(0);
    } else {
      setImageId(prevState => prevState + 1);
    }
  };

  const setImageIdDecrementHandler = () => {
    if (imageId <= 0) {
      setImageId(bags[sliderId].images.length - 1);
    } else {
      setImageId(prevState => prevState - 1);
    }
  };
  return (
    <div>
      <Header isLogoWhite={false} setIsLogoWhite={setIsLogoWhite} />
      <div className={css['product-page']}>
        <div className={css['product-page__top']}>
          <ProductPageSlider
            smallImages={bags[sliderId].thumbnails}
            originalSizeImages={bags[sliderId].images}
            middleImage={middleImage}
            largeImage={largeImage}
            setImageId={setImageId}
            imageId={imageId}
            userDeviceScreenSize={userDeviceScreenSize}
            setImageIdIncrementHandler={setImageIdIncrementHandler}
            setImageIdDecrementHandler={setImageIdDecrementHandler}
            setExamplePhotoToggle={setExamplePhotoToggle}
            examplePhotoToggle={examplePhotoToggle}
            loading={bagPageLoading}
            setContactToggle={setContactToggle}
            backgroundPageScrollOn={backgroundPageScrollOn}
            backgroundPageScrollOff={backgroundPageScrollOff}
            isAeonPage={title === 'Aeon'}
          />
          <ProductPageInformation
            seasonName={seasonName}
            title={title}
            setPriceRequestToggle={setPriceRequestToggle}
            priceRequestToggle={priceRequestToggle}
            backgroundPageScrollOff={backgroundPageScrollOff}
          />
        </div>
        <ProductPageIntroduction seasonName={seasonName} introductionInfo={introductionInfo} />
        <ProductPagePackaging
          type={packagingType}
          onChangeType={handleOnChangeType}
          packagingBoxes={packagingBoxes}
          setExamplePhotoToggle={setExamplePhotoToggle}
          backgroundPageScrollOff={backgroundPageScrollOff}
          examplePhotoToggle={examplePhotoToggle}
          backgroundPageScrollOn={backgroundPageScrollOn}
        />
      </div>
      <ProductBuyInfo
        setPriceRequestToggle={setPriceRequestToggle}
        backgroundPageScrollOff={backgroundPageScrollOff}
      />
      {priceRequestToggle && (
        <PortaledComponent
          modal={
            <PriceRequest
              packagingBoxes={packagingBoxes}
              type={packagingType}
              title={title}
              seasonName={seasonName}
              onChangeType={handleOnChangeType}
              frontImage={bags[sliderId].images[0]}
              backgroundPageScrollOn={backgroundPageScrollOn}
              setPriceRequestToggle={setPriceRequestToggle}
              priceRequestToggle={priceRequestToggle}
              isToggled={contactToggle}
              isOpenConfirmationModal={isOpenConfirmationModal}
              toggleConfirmationModal={toggleConfirmationModal}
              inputHandler={inputHandler}
              countryHandler={countryHandler}
              mail={mail}
              message={message}
              errors={errors}
              messageHandler={messageHandler}
              handleSubmit={handleSubmit}
              setContactToggle={setContactToggle}
              setCountry={setCountry}
              country={country}
              confirm={confirm}
              setConfirm={setConfirm}
            />
          }
        />
      )}
    </div>
  );
};

export default ProductPage;
