// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewProductSlider_all-product__2G3qH {\n  position: relative;\n  padding-top: 120px;\n}\n@media (max-width: 1200px) {\n  .NewProductSlider_all-product__2G3qH {\n    padding-top: 50px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/NewProductSlider/NewProductSlider.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".all-product {\n  position: relative;\n  padding-top: 120px;\n}\n@media (max-width: 1200px) {\n  .all-product {\n    padding-top: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"all-product": "NewProductSlider_all-product__2G3qH"
};
export default ___CSS_LOADER_EXPORT___;
