import React from 'react';
import css from './ContactUs.module.css';

const instagramLink = 'https://www.instagram.com/narin_bags/';
const info =
  '   We are always happy hearing from you. Feel free to contact us at info@narinbags.com';
const instagramInfo = 'Or reach us on Instagram';
const title = 'Contact us';

const ContactUs = ({ setContactToggle, backgroundPageScrollOff }) => (
  <section className={css['contact-us']} id="contact-us">
    <h1 className={css['title']}>{title}</h1>
    <div className={css['description']}>{info}</div>
    <button
      className={css['contact-us__btn']}
      onClick={() => {
        setContactToggle(() => true);
        backgroundPageScrollOff();
      }}
    >
      {title}
    </button>
    <div className={css['contact-us__social']}>
      <div className={css['description']}>{instagramInfo}</div>
      <a href={instagramLink} target="_blank">
        <img
          src="/assets/instagram.svg"
          alt="instagram"
          className={css['contact-us__social--instagram-icon']}
        />
      </a>
    </div>
  </section>
);

export default ContactUs;
